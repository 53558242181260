<template>
  <mu-container class="cart">
    <mu-paper :z-depth="2">
      <mu-list v-if='cartedThreads.length == 0'>
        <mu-list-item>
            <mu-list-item-content>
              <mu-list-item-title>
                Nothing in cart yet.
              </mu-list-item-title>
            </mu-list-item-content>
          </mu-list-item>
      </mu-list>
      <mu-list v-else>
        <div v-for="thread in cartedThreads" :key="thread.tid">
          <mu-list-item>
            <mu-list-item-content>
              <mu-list-item-title>
                <a href='javascript:void(0);' @click='cart(thread)' style="color: black">{{thread.title}}</a>
              </mu-list-item-title>
            </mu-list-item-content>
          </mu-list-item>
          <mu-divider></mu-divider>
        </div>
        <mu-list-item>
          <mu-list-item-content>
            <mu-list-item-title>
              <a href="/cart" style="color: black">前往「购物车」</a>
            </mu-list-item-title>
          </mu-list-item-content>
        </mu-list-item>
      </mu-list>
    </mu-paper>
  </mu-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ShoppingCart',
  computed: {
    ...mapState(['cartedThreads'])
  },
  methods: {
    cart: function (thread) {
      this.$store.commit('cartOperator', thread)
    }
  }
}
</script>

<style lang="stylus">
.cart
  margin-top 40px
  margin-bottom 20px
  @media screen and (max-width 768px) {
    margin-top 20px
  }
  > div
    display flex
    justify-content center
    align-items center
</style>
