<template>
  <mu-container>
    <mu-flex justify-content="center" class="pagination">
      <mu-pagination :total="totalPage" :current="currentPage" @change="handleChange" :page-count=6 :page-size=1></mu-pagination>
    </mu-flex>
  </mu-container>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    totalPage: Number,
    currentPage: Number
  },
  methods: {
    handleChange: function (newPage) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: newPage
        }
      }).catch(err => console.log(err))
    }
  }
}
</script>

<style lang="stylus" scoped>
.pagination
  margin-top 20px
  margin-bottom 20px
  @media screen and (max-width 768px) {
    margin-bottom 0px
  }
</style>
