<template>
  <mu-container>
    <mu-row gutter>
      <mu-col span="12" md="8">
        <template v-if="threads">
          <ThreadsList :threads='threads' :loading='loading'/>
          <Pagination :totalPage='totalPage' :currentPage='page'/>
        </template>
        <mu-container v-else-if="loading" class="loading">
            <mu-expansion-panel v-loading="loading" data-mu-loading-size="24"></mu-expansion-panel>
        </mu-container>
      </mu-col>
      <mu-col span="12" md="4">
        <ShoppingCart/>
      </mu-col>
    </mu-row>
  </mu-container>
</template>

<script>
import { mapState } from 'vuex'
import ThreadsList from '@/components/ThreadsList.vue'
import Pagination from '@/components/Pagination.vue'
import ShoppingCart from '@/components/ShoppingCart.vue'

export default {
  name: 'Search',
  data () {
    return {
      threads: null,
      totalPage: 1,
      s: this.$route.query.s,
      fid: Number(this.$route.query.fid) || 0,
      page: Number(this.$route.query.page) || 1,
      loading: false
    }
  },
  computed: {
    ...mapState(['cartedThreads'])
  },
  components: {
    ThreadsList, Pagination, ShoppingCart
  },
  mounted () {
    this.loading = true
    this.fetchData()
  },
  watch: {
    $route (to, from) {
      this.page = this.$route.query.page
      this.loading = true
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      var url = ''
      if (this.fid === 0) {
        url = `${process.env.VUE_APP_API}/search?keyword=${this.s}&page=${this.page}&perpage=${process.env.VUE_APP_PERPAGE}`
      } else {
        url = `${process.env.VUE_APP_API}/search?keyword=${this.s}&page=${this.page}&fid=${this.fid}&perpage=${process.env.VUE_APP_PERPAGE}`
      }
      this.$axios
        .get(url)
        .then(response => {
          this.loading = false
          if (!response.data.threads) {
            this.threads = [{
              tid: '100',
              title: '无结果',
              author: 'System',
              date: '0',
              url: 'https://jonbgua.com',
              baidupan: false
            }]
            this.totalPage = 1
          } else {
            this.threads = response.data.threads
            this.totalPage = Number(response.data.pageCount)
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          if (error.response.status === 401) {
            this.$toast.error('未登录')
            this.$router.push({ path: '/login' })
          } else {
            this.$toast.error('获取数据失败')
          }
        })
    },
    // 购物车
    addToCart (thread) {
      this.$store.commit('addToCart', thread)
    }
  }
}
</script>

<style lang="stylus" scoped>
.loading
  position relative
  top 40px
</style>
